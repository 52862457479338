.svg-title {
  @apply w-auto h-2 md:h-3;
}

.svg-title-2 {
  @apply h-[10px] md:h-[14px];
}

header .logo-sabir {
  width: 72px;
  @apply h-5 md:h-8 md:w-32;
}

footer .logo-sabir {
  width: 72px;
  @apply h-5 md:w-36 md:h-10;
}

.hover-image-el {
  max-width: 900px;
  max-height: 650px;
  @apply w-auto h-auto;
}

footer .colored-space {
  @apply h-36 w-6/12 absolute lg:h-48;
}

.colored-space {
  @apply h-24 w-6/12 absolute md:h-40 md:w-7/12;
}

.colored-space-2 {
  @apply h-24 w-6/12 absolute md:h-32 md:w-7/12 lg:h-100;
}

.colored-space-y {
  @apply h-full w-1/6 absolute;
}
