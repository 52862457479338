.menu-overlay {
  transform: translateY(0);
  transition: all 0.3s ease;
  visibility: hidden;

  @apply bg-white w-full opacity-0 absolute top-0 left-0;

  &.visible {
    opacity: 1;
    visibility: visible;
  }
}
