:root {
  --p-small-fs: 15px;
  --p-fs: 18px;
  --p-big-fs: 22px;
  --h2-fs: 48px;
  --h3-fs: 20px;
  --h4-fs: 20px;
  --h5-fs: 18px;
  --real-li-fs: 12px;
  --nav-fs: 14px;
  --container-padding: 2rem;
}

@screen md {
  :root {
    --p-small-fs: 18px;
    --p-fs: 23px;
    --p-big-fs: 32px;
    --h2-fs: 78px;
    --h3-fs: 22px;
    --h4-fs: 24px;
    --h5-fs: 22px;
    --container-padding: 3rem;
  }
}

@screen lg {
  :root {
    --p-small-fs: 22px;
    --p-fs: 27px;
    --p-big-fs: 40px;
    --h2-fs: 120px;
    --h4-fs: 26px;
    --h5-fs: 27px;
    --real-li-fs: 14px;
    --container-width: 1440px;
    --container-padding: 4rem;
  }
}

@screen xl {
  --container-padding: 5rem;
  --container-width: 1680px;
}
