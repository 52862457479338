.contacts.grid {
  :nth-child(3n + 1) {
    @apply md:justify-self-start;
  }

  :nth-child(3n + 2) {
    @apply md:justify-self-center;
  }

  :nth-child(3n + 3) {
    @apply md:justify-self-end;
  }
}
