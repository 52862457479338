a {
  @apply border-b border-black-900 ease;

  &:hover {
    @apply border-white;
  }
}

.hover-image {
  @apply text-blue cursor-pointer;

  b {
    @apply border-b;
  }
}

nav a {
  @apply border-none;
}

@screen lg {
  .hover-image {
    @apply cursor-pointer;
  }

  .agence a,
  .realisation a {
    @apply text-blue no-underline border-none;
  }
}

.agence a,
.realisation a {
  @apply text-blue no-underline border-none;
}

aside.intro a {
  @apply border-b border-black-900 hover:border-white;

  &::after {
    content: " ↓";
    @apply text-black-900 ease;
  }

  &:hover {
    &::after {
      @apply text-white;
    }
  }
}
