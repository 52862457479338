// UL list

ul {
  list-style-type: disc;
  @apply pl-4;

  li {
    padding: 2px 0;
  }
}

ol {
  list-style-type: decimal;
}

ul.realisation-titre {
  padding-left: 0;
}

#competences ol {
  @apply list-none md:pr-8 lg:scroll-pr-16;

  li {
    @apply inline-block md:my-3 border-b border-white last:border-b-0 pb-6 w-full;

    h5::after {
      content: "*";
      color: theme("colors.purple");
    }
  }
}
