@charset "UTF-8";
@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind screens;
:root {
  --p-small-fs: 15px;
  --p-fs: 18px;
  --p-big-fs: 22px;
  --h2-fs: 48px;
  --h3-fs: 20px;
  --h4-fs: 20px;
  --h5-fs: 18px;
  --real-li-fs: 12px;
  --nav-fs: 14px;
  --container-padding: 2rem; }

@screen md {
  :root {
    --p-small-fs: 18px;
    --p-fs: 23px;
    --p-big-fs: 32px;
    --h2-fs: 78px;
    --h3-fs: 22px;
    --h4-fs: 24px;
    --h5-fs: 22px;
    --container-padding: 3rem; } }

@screen lg {
  :root {
    --p-small-fs: 22px;
    --p-fs: 27px;
    --p-big-fs: 40px;
    --h2-fs: 120px;
    --h4-fs: 26px;
    --h5-fs: 27px;
    --real-li-fs: 14px;
    --container-width: 1440px;
    --container-padding: 4rem; } }

@screen xl {
  --container-padding: 5rem;
  --container-width: 1680px; }

strong {
  font-weight: 700; }

@font-face {
  font-family: 'Nimbus Sans';
  src: url("../fonts/NimbusSansEETOT-Regu.woff2") format("woff2"), url("../fonts/NimbusSansEETOT-Regu.woff") format("woff"), url("../fonts/NimbusSansEETOT-Regu.otf") format("opentype");
  font-weight: 400;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Whitman Display';
  src: url("../fonts/WhitmanDisplay-Light.woff2") format("woff2"), url("../fonts/WhitmanDisplay-Light.woff") format("woff"), url("../fonts/WhitmanDisplay-Light.otf") format("opentype");
  font-weight: 300;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Hatton';
  src: url("../fonts/Hatton-Light.woff2") format("woff2"), url("../fonts/Hatton-Light.woff") format("woff"), url("../fonts/Hatton-Light.ttf") format("opentype"), url("../fonts/Hatton-Light.otf") format("truetype"), url("../fonts/Hatton-Light.eot") format("embedded-opentype");
  font-weight: 300;
  font-style: normal;
  font-display: swap; }

.nimbus-sans {
  font-family: "Nimbus Sans", sans-serif;
  font-weight: 400; }

.whitman-display {
  font-family: "Whitman Display", serif;
  font-weight: 300; }

.hatton {
  font-family: "Hatton", serif;
  font-weight: 300; }

.realisation .hover-image-el {
  left: 50%;
  transform: translate(-50%, -50%);
  top: 50%; }

img.hover-image-el {
  @apply ease;
  opacity: 0; }
  img.hover-image-el.appear {
    opacity: 1; }

.realisation main a {
  position: relative;
  z-index: 3;
  @apply ease;
  @apply hover:lg:text-white; }
  .realisation main a ~ img {
    opacity: 0;
    @apply ease; }
  .realisation main a:hover {
    @apply border-none; }
    .realisation main a:hover ~ img {
      position: absolute;
      z-index: 2;
      opacity: 1; }

h2,
#competences li {
  transition: all 1s ease-in-out;
  opacity: 0;
  transform: translate(20px, -5px); }
  h2.in-view,
  #competences li.in-view {
    opacity: 1;
    transform: translate(0, 0); }

p.big {
  transition: all 1s ease-in-out;
  opacity: 0;
  transform: translate(0, 20px); }
  p.big.in-view {
    opacity: 1;
    transform: translate(0, 0); }

.realisation aside {
  transition: all 0.7s ease-in-out;
  opacity: 0;
  transform: translate(0, -10px); }

.realisation.in-view aside {
  opacity: 1;
  transform: translate(0, 0); }

.realisation main {
  transition: all 0.7s ease-in-out;
  opacity: 0;
  transform: translate(20px, 0); }

.realisation.in-view main {
  opacity: 1;
  transform: translate(0, 0); }

.dim {
  opacity: 1;
  transition: opacity 0.3s ease; }
  .dim:hover {
    opacity: 0.65; }

body {
  font-family: 'Whitman Display', serif;
  font-weight: 300;
  font-style: normal;
  font-size: var(--p-fs);
  line-height: 1.5;
  color: theme("colors.black.900");
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden; }

aside,
footer,
section {
  width: 100vw;
  overflow: hidden; }

img {
  display: block;
  width: 100%;
  height: auto; }

* {
  -webkit-font-smoothing: antialiased !important; }

.ease {
  transition: all 0.3s ease-in-out; }

/* Container CSS */
.container {
  max-width: var(--container-width);
  padding-left: var(--container-padding) !important;
  padding-right: var(--container-padding) !important;
  @apply mx-auto; }

@screen xl {
  .container-xl {
    max-width: var(--container-width);
    padding-left: var(--container-padding) !important;
    padding-right: var(--container-padding) !important;
    @apply mx-auto; } }

p {
  font-style: normal;
  font-size: var(--p-fs);
  line-height: 1.5;
  @apply mb-6 text-black-900; }
  p:not(.big):not(.opacity-50):not(.no-indent)::first-letter {
    margin-left: 20px; }

h2 a,
h4 a,
h5 a {
  font-family: inherit;
  font-size: inherit;
  text-transform: none;
  letter-spacing: normal; }

h2 {
  font-family: 'Hatton', serif;
  font-weight: 300;
  font-size: var(--h2-fs);
  line-height: 1.25;
  text-transform: lowercase; }

h3 {
  font-size: var(--h3-fs);
  @apply nimbus-sans text-blue leading-snug uppercase; }

h4 {
  font-family: 'Nimbus Sans', sans-serif;
  font-weight: 400;
  font-size: var(--h4-fs);
  line-height: 1.25;
  @apply text-blue; }

h5 {
  font-family: 'Hatton', serif;
  font-weight: 400;
  font-size: var(--h5-fs);
  line-height: 1.25; }

p.big {
  font-family: 'Hatton', serif;
  font-weight: 300;
  font-size: var(--p-big-fs);
  line-height: 1.25;
  @apply text-white; }

#lagence p {
  letter-spacing: -0.6px; }

.small,
.small p {
  font-family: 'Whitman Display', serif;
  font-style: normal;
  font-weight: 400;
  font-size: var(--p-small-fs);
  line-height: 1.6;
  letter-spacing: 0.25px;
  @apply text-black-900; }

ul.realisation-titre li {
  font-family: 'Nimbus Sans', sans-serif;
  font-weight: 400;
  font-size: var(--real-li-fs);
  line-height: 1.5;
  list-style: none; }
  ul.realisation-titre li::before {
    content: "→"; }

header nav a,
span.competence-number,
.sub-footer p {
  font-family: 'Nimbus Sans', sans-serif;
  font-weight: 400;
  font-size: var(--nav-fs);
  line-height: 1.25;
  letter-spacing: normal;
  @apply mx-3; }

span.competence-number {
  @apply mx-0; }

header nav a {
  @apply text-black-600 lg:mx-8; }

.sub-footer p {
  @apply mx-0 text-white; }

a {
  @apply border-b border-black-900 ease; }
  a:hover {
    @apply border-white; }

.hover-image {
  @apply text-blue cursor-pointer; }
  .hover-image b {
    @apply border-b; }

nav a {
  @apply border-none; }

@screen lg {
  .hover-image {
    @apply cursor-pointer; }
  .agence a,
  .realisation a {
    @apply text-blue no-underline border-none; } }

.agence a,
.realisation a {
  @apply text-blue no-underline border-none; }

aside.intro a {
  @apply border-b border-black-900 hover:border-white; }
  aside.intro a::after {
    content: " ↓";
    @apply text-black-900 ease; }
  aside.intro a:hover::after {
    @apply text-white; }

ul {
  list-style-type: disc;
  @apply pl-4; }
  ul li {
    padding: 2px 0; }

ol {
  list-style-type: decimal; }

ul.realisation-titre {
  padding-left: 0; }

#competences ol {
  @apply list-none md:pr-8 lg:scroll-pr-16; }
  #competences ol li {
    @apply inline-block md:my-3 border-b border-white last:border-b-0 pb-6 w-full; }
    #competences ol li h5::after {
      content: "*";
      color: theme("colors.purple"); }

.svg-title {
  @apply w-auto h-2 md:h-3; }

.svg-title-2 {
  @apply h-[10px] md:h-[14px]; }

header .logo-sabir {
  width: 72px;
  @apply h-5 md:h-8 md:w-32; }

footer .logo-sabir {
  width: 72px;
  @apply h-5 md:w-36 md:h-10; }

.hover-image-el {
  max-width: 900px;
  max-height: 650px;
  @apply w-auto h-auto; }

footer .colored-space {
  @apply h-36 w-6/12 absolute lg:h-48; }

.colored-space {
  @apply h-24 w-6/12 absolute md:h-40 md:w-7/12; }

.colored-space-2 {
  @apply h-24 w-6/12 absolute md:h-32 md:w-7/12 lg:h-100; }

.colored-space-y {
  @apply h-full w-1/6 absolute; }

header {
  position: sticky;
  position: -webkit-sticky;
  top: -1px;
  z-index: 10;
  padding-top: 1px; }

header.isSticky nav:first-of-type a:last-child {
  display: none; }

footer {
  position: relative; }

header nav a:last-child {
  @apply flex items-center; }
  header nav a:last-child::after {
    @apply w-4 ml-3 inline-block mt-1; }

.menu-overlay {
  transform: translateY(0);
  transition: all 0.3s ease;
  visibility: hidden;
  @apply bg-white w-full opacity-0 absolute top-0 left-0; }
  .menu-overlay.visible {
    opacity: 1;
    visibility: visible; }

.contacts.grid :nth-child(3n + 1) {
  @apply md:justify-self-start; }

.contacts.grid :nth-child(3n + 2) {
  @apply md:justify-self-center; }

.contacts.grid :nth-child(3n + 3) {
  @apply md:justify-self-end; }
