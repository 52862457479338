@mixin Light() {
  font-weight: 300;
}

@mixin Normal() {
  font-weight: 400;
}

@mixin Medium() {
  font-weight: 500;
}

@mixin Bold() {
  font-weight: 700;
}

@mixin Hatton() {
  font-family: 'Hatton', serif;
}

@mixin Nimbus() {
  font-family: 'Nimbus Sans', sans-serif;
}

@mixin Whitman() {
  font-family: 'Whitman Display', serif;
}

strong {
  @include Bold();
}

@mixin Dim() {
  opacity: 1;
  transition: opacity 0.3s ease;

  &:hover {
    opacity: 0.65;
  }
}

// @mixin Bold() {
//   font-weight:700;
// }

// @mixin ExtraBold() {
//   font-weight:900;
// }

@mixin translator($x, $y) {
  transform: translateX($x) translateY($y);
}

@mixin tran_simple($timing) {
  transition: all $timing ease-in-out;
}

@mixin tran_adv($property, $timing) {
  transition: $property $timing ease-in-out !important;
}

// @mixin tran_bezier($timing) {
//   transition: all $timing $bezier;
// }