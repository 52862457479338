p {
  font-style: normal;
  font-size: var(--p-fs);
  line-height: 1.5;
  @apply mb-6 text-black-900;

  &:not(.big):not(.opacity-50):not(.no-indent)::first-letter {
    margin-left: 20px;
  }
}

h2,
h4,
h5 {
  a {
    font-family: inherit;
    font-size: inherit;
    text-transform: none;
    letter-spacing: normal;
  }
}

h2 {
  @include Hatton();
  @include Light();
  font-size: var(--h2-fs);
  line-height: 1.25;
  text-transform: lowercase;
}

h3 {
  font-size: var(--h3-fs);
  @apply nimbus-sans text-blue leading-snug uppercase;
}

h4 {
  @include Nimbus();
  @include Normal();
  font-size: var(--h4-fs);
  line-height: 1.25;
  @apply text-blue;
}

h5 {
  @include Hatton();
  @include Normal();
  font-size: var(--h5-fs);
  line-height: 1.25;
}

p.big {
  @include Hatton();
  @include Light();
  font-size: var(--p-big-fs);
  line-height: 1.25;
  @apply text-white;
}

#lagence p {
  letter-spacing: -0.6px;
}

.small,
.small p {
  @include Whitman();
  font-style: normal;
  @include Normal();
  font-size: var(--p-small-fs);
  line-height: 1.6;
  letter-spacing: 0.25px;
  @apply text-black-900;
}

ul.realisation-titre li {
  @include Nimbus();
  @include Normal();
  font-size: var(--real-li-fs);
  line-height: 1.5;
  list-style: none;

  &::before {
    content: "→";
  }
}

header nav a,
span.competence-number,
.sub-footer p {
  @include Nimbus();
  @include Normal();
  font-size: var(--nav-fs);
  line-height: 1.25;
  letter-spacing: normal;
  @apply mx-3;
}

span.competence-number {
  @apply mx-0;
}

header nav a {
  @apply text-black-600 lg:mx-8;
}

.sub-footer p {
  @apply mx-0 text-white;
}
