// Homepage - Image appear on hover
.realisation .hover-image-el {
  left: 50%;
  transform: translate(-50%, -50%);
  top: 50%;
}

img.hover-image-el {
  @apply ease;
  opacity: 0;

  &.appear {
    opacity: 1;
  }
}

.realisation main a {
  position: relative;
  z-index: 3;
  @apply ease;

  & ~ img {
    opacity: 0;
    @apply ease;
  }

  &:hover {
    @apply border-none;

    & ~ img {
      position: absolute;
      z-index: 2;
      opacity: 1;
    }
  }

  @apply hover:lg:text-white;
}

// Animations on IntersectionObserver

h2,
#competences li {
  @include tran_simple(1s);
  opacity: 0;
  transform: translate(20px, -5px);

  &.in-view {
    opacity: 1;
    transform: translate(0, 0);
  }
}

p.big {
  @include tran_simple(1s);
  opacity: 0;
  transform: translate(0, 20px);

  &.in-view {
    opacity: 1;
    transform: translate(0, 0);
  }
}

.realisation {
  aside {
    @include tran_simple(0.7s);
    opacity: 0;
    transform: translate(0, -10px);
  }

  &.in-view aside {
    opacity: 1;
    transform: translate(0, 0);
  }
}

.realisation {
  main {
    @include tran_simple(0.7s);
    opacity: 0;
    transform: translate(20px, 0);
  }

  &.in-view main {
    opacity: 1;
    transform: translate(0, 0);
  }
}

.dim {
  opacity: 1;
  transition: opacity 0.3s ease;

  &:hover {
    opacity: 0.65;
  }
}
