header {
  position: sticky;
  position: -webkit-sticky;
  top: -1px;
  z-index: 10;
  padding-top: 1px;
}

header.isSticky nav:first-of-type a:last-child {
  display: none;
}

footer {
  position: relative;
}

header nav a:last-child {
  @apply flex items-center;

  &::after {
    @apply w-4 ml-3 inline-block mt-1;
  }
}
