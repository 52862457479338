body {
  @include Whitman();
  @include Light();
  font-style: normal;
  font-size: var(--p-fs);
  line-height: 1.5;
  color: theme("colors.black.900");
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

//
aside,
footer,
section {
  width: 100vw;
  overflow: hidden;
}

img {
  display: block;
  width: 100%;
  height: auto;
}

* {
  -webkit-font-smoothing: antialiased !important;
}

.ease {
  @include tran_simple(0.3s);
}

/* Container CSS */

.container {
  max-width: var(--container-width);
  padding-left: var(--container-padding) !important;
  padding-right: var(--container-padding) !important;
  @apply mx-auto;
}

.container-xl {
  @screen xl {
    max-width: var(--container-width);
    padding-left: var(--container-padding) !important;
    padding-right: var(--container-padding) !important;
    @apply mx-auto;
  }
}
