@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind screens;

@import "abstracts/variables", "abstracts/mixins", "abstracts/placeholders";

@import "base/fonts", "base/animations", "base/commons", "base/typography",
  "base/icons", "base/ie", "base/links", "base/print";

@import "components/button", "components/lists", "components/forms";

@import "layout/general", "layout/header", "layout/layermenu", "layout/footer";

@import "pages/e404";
