@font-face {
  font-family: 'Nimbus Sans';
  src: url('../fonts/NimbusSansEETOT-Regu.woff2') format('woff2'),
    url('../fonts/NimbusSansEETOT-Regu.woff') format('woff'),
    url('../fonts/NimbusSansEETOT-Regu.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Whitman Display';
  src: url('../fonts/WhitmanDisplay-Light.woff2') format('woff2'),
    url('../fonts/WhitmanDisplay-Light.woff') format('woff'),
    url('../fonts/WhitmanDisplay-Light.otf') format('opentype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Hatton';
  src: url('../fonts/Hatton-Light.woff2') format('woff2'),
    url('../fonts/Hatton-Light.woff') format('woff'),
    url('../fonts/Hatton-Light.ttf') format('opentype'),
    url('../fonts/Hatton-Light.otf') format('truetype'),
    url('../fonts/Hatton-Light.eot') format('embedded-opentype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

.nimbus-sans {
  font-family: "Nimbus Sans", sans-serif;
  font-weight: 400;
}

.whitman-display {
  font-family: "Whitman Display", serif;
  font-weight: 300;
}

.hatton {
  font-family: "Hatton", serif;
  font-weight: 300;
}